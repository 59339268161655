<!--
 * @Author: 刘格优
 * @Date: 2020-03-06 16:14:18
 * @LastEditors: 刘格优
 * @LastEditTime: 2020-07-20 15:33:44
 -->

<template>
  <div class="container">
    <div class="top">
      <img src="@/assets/nh/nhyx.jpg"
           alt="">
    </div>
    <div class="two">
      <div class="twotop">
        <img src="@/assets/nh/nhyxq.png"
             alt="">
        <span>我的奖励</span>
      </div>
      <div class="twobottom">
        <div>
          <p class="num">{{Data.hhr}}</p>
          <p>我的合伙人</p>
        </div>
        <div>
          <p class="num">{{Data.cdhhr}}</p>
          <p>已出单合伙人</p>
        </div>
        <div>
          <p class="num">{{Data.jl}}</p>
          <p>已获合伙人奖励</p>
        </div>
      </div>
      <div class="twodeep">
        <span @click="sjshow()">查看数据明细>></span>
      </div>
    </div>
    <!-- <div class="three">
      <p>合伙人邀请制度</p>
      <img src="@/assets/nh/nhwxdq.png"
           alt="">
      <div>点击本页面邀请好友按钮->按邀新页面指引邀请好友->好友注册成为合伙人->合伙人推广产品->获得合伙人保费 <span class="yellow">2%</span>的邀请奖励</div>
    </div> -->
    <div class="four">
      <p>合伙人推荐奖励</p>
      <img src="@/assets/nh/nhwxbl.png"
           alt="">
      <div class="yop">
        邀请好友成为合伙人，好友成功推广产品，您可额外活动与好友收入<span class="yellow">30%</span>等额的现金奖励（含平台补贴部分），不设上限，长期有效！
      </div>
      <div>
        邀请流程：点击【邀请好友】-按邀新页面指引转发给好友注册认证-好友推广产品-好友获得高额推广费，您获得推荐费
      </div>
      <div class="bottom">
        举个例子：比如您邀请的好友合伙人成功推广出单一款保费
        <span class="yellow">1000元</span>的产品，推广费比例
        <span class="yellow">
          70%
        </span>
        ，好友可以收入<span class="yellow">
          700元
        </span>，则您可以额外躺赚<span class="yellow">
          700元*30%=210元
        </span>。长期有效哦！
      </div>
    </div>
    <div class="hdgz"
         @click="togz">
      活动规则
    </div>
    <div class="foot"
         @click="toinvite">
      邀请好友
    </div>
  </div>
</template>

<script>
import { getYXTotal } from '@/api/agent/agent'
export default {
  data () {
    return {
      showFullScreen: false,
      Data: {
        cdhhr: '0',
        hhr: '0',
        jl: '0'
      },
      lists: []
    }
  },
  created () {
    this.getdata()
  },
  methods: {
    getdata () {
      getYXTotal().then((res) => {
        this.Data = res.data.data
      })
    },
    toinvite () {
      this.$router.push('/Invitedetail')
    },
    togz () {
      this.$router.push('/rshdgz')
    },
    sjshow () {
      this.$router.push('/jldetail')
    }
  }
}

</script>
<style scoped lang="stylus">
@import './index.styl';
</style>
